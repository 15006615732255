<template>
  <div class="aboutus">
    <!--  -->
    <div class="one-bg-box">
      <div class="obb-box">
        <div class="obb-tit">热烈庆祝中国共产党成立100周年</div>
      </div>
    </div>
    <!--  -->
    <div class="part-building-box">
      <div class="pbb-tit-en">Party mass center</div>
      <div class="pbb-tit">
        <div class="pbbt-line"></div>
        <div class="pbbt-tit">党群建设</div>
      </div>
      <div class="pbb-content">
        <div class="pbbc-item">
          <li class="pbbci-left">
            按键开关看见啦苏联空军健康金克拉是会计靠捡垃圾可根据凯撒经过艰苦看见啦手机卡时间卡箍就开始看见旮角看过啦可就是更健康公交卡数据库工卡卡时间看过
          </li>
          <div class="pbbci-right">2021-09-30</div>
        </div>
        <div class="pbbc-item">
          <li class="pbbci-left">
            按键开关看见啦苏联空军健康金克拉是会计靠捡垃圾可根据凯撒经过艰苦看见啦手机卡时间卡箍就开始看见旮角看过啦可就是更健康公交卡数据库工卡卡时间看过
          </li>
          <div class="pbbci-right">2021-09-30</div>
        </div>
        <div class="pbbc-item">
          <li class="pbbci-left">
            按键开关看见啦苏联空军健康金克拉是会计靠捡垃圾可根据凯撒经过艰苦看见啦手机卡时间卡箍就开始看见旮角看过啦可就是更健康公交卡数据库工卡卡时间看过
          </li>
          <div class="pbbci-right">2021-09-30</div>
        </div>
        <div class="pbbc-item">
          <li class="pbbci-left">
            按键开关看见啦苏联空军健康金克拉是会计靠捡垃圾可根据凯撒经过艰苦看见啦手机卡时间卡箍就开始看见旮角看过啦可就是更健康公交卡数据库工卡卡时间看过
          </li>
          <div class="pbbci-right">2021-09-30</div>
        </div>
      </div>
    </div>
    <page-bottom></page-bottom>
  </div>
</template>
<script>
import PageBottom from "../components/PageBottom.vue";
export default {
  components: {
    PageBottom,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
}
.aboutus {
  .one-bg-box {
    height: 400px;
    background-image: url("../static/partybg.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .obb-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .obb-tit {
        margin-top: 20px;
        font-size: 26px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #e40316;
        opacity: 1;
      }
    }
  }

  .part-building-box {
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .pbb-tit-en {
      font-size: 25px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #2e86a9;
      opacity: 1;
    }
    .pbb-tit {
      width: 600px;
      .pbbt-line {
        margin-top: 50px;
        width: 100%;
        border-bottom: 1px solid #000000;
      }
      .pbbt-tit {
        height: 40px;
        text-align: center;
        top: -20px;
        left: 200px;
        position: relative;
        width: 200px;
        background-color: #fff;
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #2e86a9;
        opacity: 1;
      }
    }
    .pbb-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 300px;
      .pbbc-item {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        .pbbci-left {
          margin-right: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          line-height: 54px;
          opacity: 1;
        }
        .pbbci-right {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 41px;
          color: #999999;
          opacity: 1;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>

